import LayoutComponent from "../LayoutComponent";
import konsole from "../network/konsole";
import Services from "../network/Services";
import { useContext, useEffect, useState } from "react";
import { CounterCheckIn, plannerFeePaid, Registered } from "../control/Constant";
import commonLib from '../control/commonLib';

import { UserContext } from "../../App";
import ConvertToExcel from "../ConvertToExcel";
import SearchBar from "../SearchBar";
import "../CounterChecking/Counter.css"



const ConfirmationList = () => {
  const [confirmation, setConfirmation] = useState([])
  const [confirmation1, setConfirmation1] = useState([])
  const [excel3, setExcel3] = useState([])
  const [excel4, setExcel4] = useState([])
  const { setLoader } = useContext(UserContext);
  const [counterData, setCounterData] = useState([]);
  const [counterData2, setCounterData2] = useState([]);
  const [query, setquery] = useState("");
  const upsertedBy = commonLib.getObjFromStorage('stateObj').userId;
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const seminarObj1 = commonLib.getObjFromStorage("seminarList2");
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const Location = commonLib.getObjFromStorage("Location");
  const [toggleButton, setToggleButton] = useState(false);
  const bookingId = commonLib.getObjFromStorage("classbookingId");
  const headings = ["S. No", "First name", "Last name", "Email", "Phone", "Assigned Date", "	Assigned Time Slot","Price","Mode of Planning",
    "Mode of Payment"
  ];
  const headings2 = ["S. No", "First name", "Last name", "Email", "Phone", "Workshop Date", "	Workshop Time","Price",
    "Mode of Payment"
  ];

  konsole.log("arpit", confirmation)
  const pastSeminarfunc = (event) => {

    konsole.log("eve")
    setToggleButton(!toggleButton);

  };
  useEffect(() => {
    classRoomBookingData(seminarObj.seminarId, true)
    getConfirmationData1();
  }, [])

  // const counterCheckInList = () => {
  //   setLoader(true);
  //   const promise = Services.getSeminarUserList(plannerFeePaid, subtenantId, seminarObj.seminarId);
  //   promise.then((res) => {
  //     setLoader(false);
  //     konsole.log("konsole", res)
  //     // konsole.log("konsole", res.data.data);
  //     let counterResFilter = res.data.data.filter((res) => {
  //       return (res.seminarId == seminarObj.seminarId)
  //     })
  //     konsole.log("semniarFilter", counterResFilter);

  //     const Array = counterResFilter[0].attendees.map((res, index) => {
  //       return {
  //         S_No: index + 1,
  //         firstName: res.fName,
  //         EmailId: res.emailId,
  //         Phone: res.mobileno,
  //         Assignd_Date: commonLib.getFormattedDate(res.plannerDate),
  //         Assignd_Time_Slot: commonLib.getFormattedTime(res.plannerTime)
  //       }
  //     })
  //     setExcel3(Array)
  //     setCounterData(counterResFilter[0])

  //   }).catch((err) => {
  //     konsole.log(`error `, err.response)
  //   }).finally(error => {
  //     konsole.log("finish");
  //     setLoader(false);
  //   })
  // }

  const classRoomBookingData = (seminarId, isActive) => {
    setLoader(true);
    const promise = Services.getClassRoomBooking(seminarId, isActive);
    promise.then((res) => {
      setLoader(false);
      konsole.log("konsole111", res?.data?.data)
      // konsole.log("konsole", res.data.data);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      konsole.log("semniarFilter", counterResFilter);
      setConfirmation1(counterResFilter)
      const Array = counterResFilter.map((res, index) => {
        return {
          S_No: index + 1,
          firstName: res.attendeeName.split(" ")[0],
          lastName: res.attendeeName.split(" ")[1],
          EmailId: res?.attendeeEmailId,
          Phone: commonLib.formatPhoneNumber(res.attendeeMobileNo?.slice(-10)),
          Workshop_Date: res.classroomFromDate + " To " + res?.classroomToDate,
          Assigned_Time_Slot: res.classroomFromTime + " To " + res?.classroomToTime,
          Mode_Of_Payment: res?.paymentType





        }
      })
      konsole.log("textTemp11", Array)
      setExcel4(Array)

    }).catch((err) => {
      konsole.log(`error `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })

  }



  const getConfirmationData1 = () => {
    //  let SlotStatusId =  2;
    let isActive = true
    let SeminarId = seminarObj.seminarId;
    //  konsole.log("datas",SeminarId)
    const promise = Services.getConfirmationData(SeminarId, isActive);
    promise.then((res) => {
      konsole.log("Slot", res.data);
      let counterResFilter1 = res.data.data.filter((res) => {
        return res.seminarId == seminarObj.seminarId;

      })
      //konsole.log("samet",counterResFilter1)
      setConfirmation(counterResFilter1)
      const Array = counterResFilter1.map((res, index) => {
        konsole.log("textTemp", res)
        return {
          S_No: index + 1,
          firstName: res.attendeeFullName.split(" ")[0], lastName: res.attendeeFullName.split(" ")[1],
          EmailId: res?.attendeeEmailId ?? res?.plannerEmailId,
          Phone: commonLib.formatPhoneNumber(res?.attendeeMobileNo?.slice(-10)),


          Assignd_Date: res?.plannerDate ? commonLib.getFormattedDate(res?.plannerDate): "No Preferred Slot" ,
          Assignd_Time_Slot: res.plannerTime ? commonLib.getFormattedTime(res.plannerTime) : "",
          Mode_Of_Payment: res?.paymentType



        }
      })
      konsole.log("arrs", Array)
      setExcel3(Array)


    }).catch((err) => {
      konsole.log("error", err)
    })



  }


  // const getConfirmationData1 = () => {
  //   let SlotStatusId = 2;
  //   let SeminarId = seminarObj.seminarId;
  //   konsole.log("datas", SeminarId)
  //   const promise = Services.getConfirmationData(SeminarId, SlotStatusId);
  //   promise.then((res) => {
  //     konsole.log("Slot", res.data.data);
  //     let counterResFilter1 = res.data.data.filter((res) => {
  //       return res.seminarId == seminarObj.seminarId
  //     })
  //     setConfirmation(counterResFilter1)
  //   }).catch((err) => {
  //     konsole.log("error", err)
  //   })



  // }



  //Sumit

  return (
    <LayoutComponent>
      <div>
        <div className="container-fluid bgclr confirmationBoooking-main-div">
          <div className="pt-1 d-flex justify-content-between w-100 mobileLocate">
            <h4>
              <b className="mobileText12">{toggleButton === false ? "Appointment Booking Confirmation List" : "Workshop Confirmation List"}</b>
            </h4>
            {/* <div className="text-start text-md-end">
              <b>
                {" "}
                <span className="fs-5">
                  Venue:
                </span>
              </b>{" "}
              {venueObj.venueAddressLine1}{" "}
              <b>
              </b>{" "}
              | {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}
            </div> */}
            <div className='p-0'>
              {/* <h5>Venue: {Location?.seminarVenue}</h5> */}
              <h6>{Location?.seminarType !== "Direct" ? "" : "Venue : " + Location?.seminarVenue}</h6>
              {/* <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p> */}
              {
                venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== "" &&
                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ? "" : "Location : " + venueObj?.venueAddressLine1}
                  {/* Location: {venueObj?.venueAddressLine1} */}
                </p>
              }
              <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
                {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
            </div>

          </div>

          <div className="Main-container-counter bg-white">
            <div className="row sticky-top-0  mobileLocateBtns">
              <div className="d-flex justify-content-between mobileLocate">
                <div className="mt-auto mb-auto ms-3 mobileText1">
                  <h6 className="counter-text mt-1  mobileText1">
                    {seminarObj?.seminarTopic}(Check Ins : {toggleButton === false ? confirmation.length + "/" + seminarObj1?.noOfSeats : confirmation1.length + "/" + seminarObj1?.noOfSeats})
                  </h6>

                </div>
                <div className="mt-auto mb-auto me-3 mobileToggle1">
                  <div className=" d-flex  mt-auto mb-auto">
                    <div className=" d-flex justify-content-between" >
                      <div className="d-flex align-items-center align-self-center mb-auto">

                        <span style={{ fontWeight: "bold" }} className={(toggleButton == true) ? "upcoming" : "past"}>Appointment Booking</span>
                      </div>
                      <div className="form-check form-switch mx-2 d-flex justify-content-center">
                        <input
                          className="form-check-input toggle shadow-none"

                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          placeholder="Past Event"
                          checked={toggleButton}
                          onChange={(event) => pastSeminarfunc(event)}


                        />
                      </div>
                      <div className="" >
                        <span style={{ fontWeight: "bold" }} className={(toggleButton == true) ? "past" : "upcoming"}>Workshop</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <h6 className="counter-text mt-3">
              {seminarObj?.seminarTopic}(Check Ins : {confirmation.length+"/"+seminarObj1?.noOfSeats})
              </h6> */}
              {/* <div className="col">
               <nav className="navbar">
                  <form className="container-fluid">
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setquery((e.target.value).toLowerCase())}
                      />
                      <span className="input-group-text" id="basic-addon1">
                        <img src="./images/vector.png" />
                      </span>
                    </div>
                  </form>
                </nav>
                </div> */}
              <div className="col-sm-12  col-md-6 col-lg-6 ms-3 mobileText1 counter-text mt-auto mb-auto">
                <SearchBar setquery={setquery} />
                {/* <div className="input-group w-75">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setquery((e.target.value)?.toLowerCase())}
                />
                <span className="input-group-text" id="basic-addon1">
                  <img src="./images/vector.png" />
                </span>
              </div> */}
              </div>
              <div className="col">
              </div>
              {/* <div className="col-sm-2 d-flex justify-content-end mt-auto mb-auto">
                <div className=" d-flex justify-content-between" >
            <div className="d-flex align-items-center align-self-center mb-auto">
             
              <span style={{fontWeight:"bold"}} className={(toggleButton == true) ? "upcoming" : "past"}>Planner</span>
            </div>
            <div className="form-check form-switch mx-2 d-flex justify-content-center">
              <input
                className="form-check-input toggle shadow-none"
                
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                placeholder="Past Event"
                checked={toggleButton}
                onChange={(event) => pastSeminarfunc(event)}
               

              />
            </div>
            <div className="">
            <span style={{fontWeight:"bold"}} className={(toggleButton == true) ? "past" : "upcoming"}>Workshop</span>
            </div>
          </div>
                </div> */}
              {toggleButton === false && excel3.length > 0 ?
                <ConvertToExcel data={excel3} headings={headings} pName={'Appointment Booking ConfirmationList.xlsx'} />
                : toggleButton === true && excel4.length > 0 ?
                  <ConvertToExcel data={excel4} headings={headings2} pName={'Workshop ConfirmationList.xlsx'} /> : ""
              }


            </div>
            {toggleButton === false ?
              (
                <div className="table-contaier  responsive mt-1">
                  <table className="table bg-white  gap-3 ">
                    <thead style={{ position: "sticky", top: -1, zIndex: '5' }}>
                      <tr className="bgclr gap-3 text-danger fs-6 ">
                        {headings.map((ele) => {
                          return (
                            // <th scope="col" className="text-center">{ele}</th>
                            <th className={ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>


                          )
                        })}

                      </tr>
                    </thead>
                    <tbody>
                      {
                        confirmation !== undefined && confirmation.length > 0 && confirmation.filter(item => item?.attendeeFullName?.toLowerCase()?.includes(query) ||

                          item?.attendeeEmailId?.toLowerCase()?.includes(query) ||
                          item?.attendeeMobileNo?.toLowerCase()?.includes(query))?.map((item, index) => {
                            const fullName = item?.attendeeFullName;
                           const firstName = fullName?.split(" ")[0]; // Get the first name
                           const lastName = fullName?.split(" ")[1]; // Get the first name
                           const capitalizedFirstName = firstName && firstName?.charAt(0)?.toUpperCase() + firstName?.slice(1); // Capitalize the first letter
                           const capitalizedLastName =  lastName && lastName?.charAt(0)?.toUpperCase() + lastName?.slice(1); // Capitalize the first letter

                            
                            return (
                              <tr key={index}>
                                <td className="">{index + 1}</td>
                                <td className="">{capitalizedFirstName}</td>
                                <td className="">{capitalizedLastName}</td>
                                <td className="">{item?.attendeeEmailId}</td>
                                <td className="">

                                  {item?.attendeeMobileNo?.slice(0, -10)}
                                  {" "}
                                  {commonLib.formatPhoneNumber(item?.attendeeMobileNo?.slice(-10))}
                                </td>
                                <td className="text-center">{item?.plannerDate ?? "No Preferred Slot"}</td>
                                <td className="text-center">{item?.plannerTime}</td>
                                <td className="text-center">{`$${item?.totalCost}`}</td>
                                <td className="text-center">{item?.planningName}</td>
                                <td className="text-center">{item?.paymentType}</td>


                              </tr>)
                          })
                      }
                    </tbody>
                  </table>
                </div>

              )
              : (
                <div className="table-contaier responsive mt-1">
                  <table className="table bg-white  gap-3 ">
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr className="bgclr gap-3 text-danger fs-6 ">
                        {headings2.map((ele) => {
                          return (
                            // <th scope="col" className="text-center">{ele}</th>
                            <th className={ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>


                          )
                        })}

                      </tr>
                    </thead>
                    <tbody>
                      {
                        confirmation1 !== undefined && confirmation1.length > 0 && confirmation1.filter(item => item?.attendeeName?.toLowerCase()?.includes(query) ||

                          item?.attendeeEmailId?.toLowerCase()?.includes(query) ||
                          item?.attendeeMobileNo?.toLowerCase()?.includes(query))?.map((item, index) => {
                            const fullName = item?.attendeeName;
                            const firstName = fullName?.split(" ")[0]; // Get the first name
                            const lastName = fullName?.split(" ")[1]; // Get the first name
                            const capitalizedFirstName = firstName && firstName?.charAt(0)?.toUpperCase() + firstName?.slice(1); // Capitalize the first letter
                            const capitalizedLastName =  lastName && lastName?.charAt(0)?.toUpperCase() + lastName?.slice(1); // Capitalize the first letter
 
                            
                            return (
                              <tr key={index}>
                                <td className="">{index + 1}</td>
                                <td className="">{capitalizedFirstName}</td>
                                <td className="">{capitalizedLastName}</td>
                                <td className="">{item?.attendeeEmailId}</td>
                                <td className="">

                                  {item?.attendeeMobileNo?.slice(0, -10)}
                                  {" "}
                                  {commonLib.formatPhoneNumber(item?.attendeeMobileNo?.slice(-10))}
                                </td>
                                <td className="text-center">{item?.classroomFromDate + " To " + item?.classroomToDate}</td>
                                <td className="text-center">{item?.classroomFromTime + " To " + item?.classroomToTime}</td>
                                <td className="text-center">{`$${item?.totalCost}`}</td>
                                <td className="text-center">{item?.paymentType}</td>
                                


                              </tr>)
                          })
                      }
                    </tbody>
                  </table>
                </div>
              )}


          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default ConfirmationList;